<template>
  <el-dialog :title="title"
             :close-on-click-modal="false"
             :visible.sync="isOpenModal"
             append-to-body
             width="600px" @open="openModal"
             @closed="closeModal">
    <div style="width: 80%">
      <ch-form
          :render-option="option"
          :model="modalForm"
          ref="form"
          :rules="rules"
          :props="{ labelWidth: '120px', paddingRight: '0px' }"
      >
      </ch-form>
    </div>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok" :loading="isSubmitLoading" @click="handleModal">确定</ch-button>
    </div>
  </el-dialog>
</template>
<script>
import {phone} from '@/utils/validator'
import {mapActions,mapGetters} from "vuex";
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data() {
    return {
      isOpenModal: false,
      isSubmitLoading: false,
      type: false,
      title: '',
      modalForm: {
        needFiveStarComment: 0,
        name: '',
        phone: '',
      },
      rules:{
        communityId: [{ required: true, message: '请选择社区', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: '请输入名称', trigger: ['blur', 'change'] }],
        phone: [{ required: true, pattern: phone, message: '请输入正确的手机号', trigger: ['blur', 'change'] }],
      }
    }
  },
  computed: {
    ...mapGetters({
      communityOption: 'charge/communityOption'
    }),
    option() {
      return [
        {type: 'select', label: '绑定社区：',  prop: 'communityId',
          name: "communityName", value: "communityId", option: this.communityOption,placeholder: '请选择'},
        {type: 'input', label: '姓名', prop: 'name', placeholder: '请输入名称'},
        {type: 'input', label: '手机号', prop: 'phone', placeholder: '请输入手机号'},
      ]
    }
  },
  created() {
    this.getCommunityOption();
  },
  methods:{
    ...mapActions({
      getCommunityOption: 'charge/getCommunityOption'
    }),
    openModal(){
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    openAdd(){
      this.isOpenModal = true
      this.type = 'add'
      this.title = '新增社区服务人员'
    },
    openEdit(row){
      this.isOpenModal = true
      this.type = 'edit'
      this.title = '编辑社区服务人员'
      const { name,phone,id,communityId } = row
      this.modalForm = {name, phone,id,communityId}
    },
    handleModal(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.isSubmitLoading = true
          if(this.type === 'add'){
            this.$curl.post('/hm/serviceStaff/addCommunity', this.modalForm).then(() => {
              this.$message.success('新增成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }else{
            this.$curl.post('/hm/serviceStaff/editCommunity', this.modalForm).then(() => {
              this.$message.success('编辑成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }

        }
      })
    },
    closeModal(){
      this.isOpenModal = false
      this.isSubmitLoading = false
      setTimeout(() => {
        this.modalForm = {}
        this.title = ''
      }, 500)
    }
  }
}
</script>
<style scoped lang="scss">

</style>